@import "../../ui/colors";
@import "../../ui/values";

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  height: $navbarheight;
  width: 100%;
  padding: 0 3vw;
  // background-color: #dbecff;
  background: #19191900;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 2px solid #000000e7;

  .logo {
    font-size: 2rem;
    letter-spacing: 0.01vw;
    cursor: pointer;
    color: wheat;
    transition: all 0.3s ease;
  }

  .desktopitems {
    .link {
      text-decoration: none;
      padding: 0.5vw 0.7vw;
      margin: 0 0.5vw;
      transition: 0.5s all ease-in-out;
      color: wheat;
      font-size: 1.3rem;
      font-weight: 500;
      letter-spacing: 1px;
      border-radius: 4px;
      transition: 0.3s all ease-in-out;

      &:hover {
        background-color: rgba(74, 77, 85, 0.5);
      }
    }
  }

  .mobileview {
    .mobilemenu {
      position: absolute;
      right: 3vw;
      top: 12.9px;
      // background-color: rgba(0, 0, 0, 0.06);
      padding: 6px 7px 4px 7px;
      border-radius: 10px;
      cursor: pointer;
      transform: scale(1);
      transition: 0.3s all ease-in-out;

      // &:hover {
      //   background-color: rgba(0, 0, 0, 0.08);
      // }
    }

    .active {
      transform: scale(0);
      transition: 0.3s all ease-in-out;
    }
  }
}

.mobileMenuModal {
  position: fixed;
  background-color: #000000f1;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 5;
  transform: translateY(-100%);
  transition: 0.4s all ease-in-out;

  padding: calc(#{$navbarheight} + 3rem) 0 3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  @media (min-width: 850px) {
    display: none;
  }
  .mobileLinks {
    text-decoration: none;
    padding: 10px;
    color: wheat;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 1px;
  }
}

.active {
  transform: translateY(0);
  transition: 0.4s all ease-in-out;
}

@media (max-width: 850px) {
  .desktopitems {
    display: none;
  }
}

@media (min-width: 850px) {
  .mobileview {
    display: none;
  }
}
