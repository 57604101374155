@import '../../ui/colors';
@import '../../ui/values';

.skills {
  min-height: 100vh;
  padding: calc(#{$navbarheight} + 1rem) 0 2rem 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6),
  rgba(0, 0, 0, 0.6)), url('../../assets/backgroundimage.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  
  .wrapper {
    max-width: 1080px;
    margin: 20px auto;
    padding: 0 20px;
    position: relative;

    .skillsopen {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 2rem;
      color: $lightwhite;
      border-radius: 10px;
      width: fit-content;
      padding: 8px 10px;
      font-family: 'Ubuntu Mono', monospace;
      letter-spacing: 0.5px;
    }

    .skillsclose {
      font-size: 2rem;
      font-weight: 600;
      margin-top: 3rem;
      color: $lightwhite;
      border-radius: 10px;
      width: fit-content;
      padding: 8px 10px;
      font-family: 'Ubuntu Mono', monospace;
      letter-spacing: 0.5px;
    }

    .skills_content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 3rem;

      a {
        font-size: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
        transition: 0.5s all ease-in-out !important;

        &:hover {
          border-radius: 50px;
          background: wheat;
          box-shadow: 0 0 6px black;
        }

        & svg {
          transition: all 0.3s ease-in-out;
        }

        &:hover svg {
          color: #2a4459 !important;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .skills {
    .wrapper {
      .skills_content {
        gap: 2rem;

        a {
          font-size: 2.5rem;
        }
      }
    }
  }
}
