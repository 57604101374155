@import './ui/fonts';
@import './ui/colors';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Ubuntu', sans-serif;
  color:wheat;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: wheat;
  border-radius: 4px;
}
